<template>
     <div class="d-flex flex-column justify-content-end" >
      <div class="">
        <div class="quizell-ai-title">
            Do you have specific questions in mind for your quiz?
        </div>
       
      </div>
      <div class="card mt-3" style="border-radius: 12px;">
  <div class="card-body" style="padding: 16px;">
    <div class="d-flex justify-content-center align-items-center">
      <div class="" style="flex:1">
        <textarea name=""   v-model="QuizPayload.prompts.i_want_to_ask_my_audience_about" class="w-100 resize-ta importQuizTextArea" placeholder="Type your questions for participants here, one time at a time..." id=""></textarea>
      </div>
     <div class="">
      <Button @click="$emit('click')" >Continue</Button>
     </div>
    </div>
  </div>
</div>
      </div>

</template>
<script>
import Button from '../../Layout/Button.vue'
export default{
  props:{
    QuizPayload:Object
  },
  methods:{
    calcHeight(value) {
  let numberOfLineBreaks = (value.match(/\n/g) || []).length;
  // min-height + lines x line-height + padding + border
  let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
  return newHeight;
},
  },
  mounted(){
  let textarea = document.querySelector(".resize-ta");
textarea.addEventListener("keyup", () => {
  textarea.style.height = this.calcHeight(textarea.value) + "px";
});
},
    components:{
        Button
    },
    watch:{
        text(newValue){
            this.$emit('input',newValue)
        }
    },
    data(){
        return{
            text:''
        }
    }
}
</script>
<style>
.importQuizTextArea{
  border:none;
  outline: none;

}
.importQuizTextArea:active{
  border:none;
  outline: none;
  
}
.info{
  font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
.quizell-ai-info {
  color: #1F1F28;
font-size: 14px;
font-style: normal;
font-weight: 500;
letter-spacing: 0.56px;
}
.quizell-ai-title{
  color: #1F1F28;

font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 500;

letter-spacing: 0.88
}</style>
